import { Inject, Optional, Pipe, PipeTransform } from '@angular/core';
import { AssetTransformer } from '@kiq/shared/interfaces';
import { ASSET_TRANSFORMER } from '../token/token';

@Pipe({
  name: 'assetsHelper',
  standalone: true,
})
export class AssetsHelperPipe implements PipeTransform {
  constructor(@Optional() @Inject(ASSET_TRANSFORMER) private assetTransformer?: AssetTransformer) {}

  transform(assetsPathWithoutHash: string | undefined): string {
    if (!assetsPathWithoutHash) {
      return '';
    }

    return this.assetTransformer
      ? this.assetTransformer?.getAssetWithHash(assetsPathWithoutHash)
      : assetsPathWithoutHash;
  }
}
