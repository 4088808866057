import { inject, Injectable } from '@angular/core';
import { ConfigFacade } from '@kiq/client/data-access/config';
import { AppInitializationState, NativeAppType } from '@kiq/shared/enums';

@Injectable({
  providedIn: 'root',
})
export class NativeAppsService {
  private readonly configFacade = inject(ConfigFacade);
  nativeAppType = this.configFacade.nativeAppType;

  openLoginModal() {
    if (this.nativeAppType()) {
      switch (this.nativeAppType()) {
        case NativeAppType.AndroidApp:
          (window as any).Android.openLogin();
          break;
        case NativeAppType.IosApp:
          (window as any).webkit.messageHandlers.appHandler.postMessage('openLogin');
          break;
      }
    }
  }

  openRegisterModal() {
    if (this.nativeAppType()) {
      switch (this.nativeAppType()) {
        case NativeAppType.AndroidApp:
          (window as any).Android.openRegister();
          break;
        case NativeAppType.IosApp:
          (window as any).webkit.messageHandlers.appHandler.postMessage('openRegister');
          break;
      }
    }
  }

  logout() {
    if (this.nativeAppType()) {
      switch (this.nativeAppType()) {
        case NativeAppType.AndroidApp:
          (window as any).Android.logout();
          break;
        case NativeAppType.IosApp:
          (window as any).webkit.messageHandlers.appHandler.postMessage('logout');
          break;
      }
    }
  }

  goToUrl(url: string) {
    if (this.nativeAppType()) {
      switch (this.nativeAppType()) {
        case NativeAppType.AndroidApp:
          (window as any).Android.goToUrl(url);
          break;
        case NativeAppType.IosApp:
          (window as any).webkit.messageHandlers.appUrlHandler.postMessage(url);
          break;
      }
    }
  }

  openNotificationModal() {
    if (this.nativeAppType()) {
      switch (this.nativeAppType()) {
        case NativeAppType.AndroidApp:
          (window as any).Android.openNotificationDialog();
          break;
        case NativeAppType.IosApp:
          (window as any).webkit.messageHandlers.appHandler.postMessage('openNotificationDialog');
          break;
      }
    }
  }

  showAppInformation() {
    if (this.nativeAppType()) {
      switch (this.nativeAppType()) {
        case NativeAppType.AndroidApp:
          (window as any).Android.showAppInformation();
          break;
        case NativeAppType.IosApp:
          (window as any).webkit.messageHandlers.appHandler.postMessage('showAppInformation');
          break;
      }
    }
  }

  setWebAppStatus(initialiedState: AppInitializationState) {
    switch (this.nativeAppType()) {
      case NativeAppType.AndroidApp:
        (window as any).Android.setWebAppStatus(initialiedState);
        break;
      case NativeAppType.IosApp:
        (window as any).webkit.messageHandlers.stateHandler.postMessage(initialiedState);
        break;
    }
  }

  navigateUserToPushSettings() {
    if (this.nativeAppType()) {
      switch (this.nativeAppType()) {
        case NativeAppType.AndroidApp:
          (window as any).Android.navigateUserToPushSettings();
          break;
        case NativeAppType.IosApp:
          (window as any).webkit.messageHandlers.appHandler.postMessage('navigateUserToPushSettings');
          break;
      }
    }
  }

  getCapabilities(): string[] | null {
    switch (this.nativeAppType()) {
      case NativeAppType.AndroidApp:
        return ((window as any)?.Android?.getCapabilities() ?? null) as string[] | null;
      case NativeAppType.IosApp:
        return ((window as any)?.iOS?.getCapabilities() ?? null) as string[] | null;
      default: {
        return null;
      }
    }
  }
}
