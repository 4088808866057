import { BoDashboardCountPerDate } from './bo-dashboard-count-per-date';
import { BoDashboardGameCounts } from './bo-dashboard-game-counts';

export type BoDashboard = {
  createTimestamp: Date;
  userRegistrationCountsLastWeek: Array<BoDashboardCountPerDate>;
  userRegistrationCountThisMonth: number;
  userRegistrationCountLastMonth: number;
  userRegistrationCountAll: number;
  gameCounts: Array<BoDashboardGameCounts>;
};
