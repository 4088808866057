import { APP_INITIALIZER, isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, TitleStrategy } from '@angular/router';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { StoreModule } from '@ngrx/store';
import { Actions, EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import {
  ASYNC_GAMES_TOKEN,
  BACKEND_TOKEN,
  BULI_LICENSE_FEATURE_FLAG_TOKEN,
  DATA_SINCE_1990_FEATURE_FLAG_TOKEN,
  FAKE_OR_FACT_FEATURE_FLAG_TOKEN,
  GAMES_OVERVIEW_AD_FEATURE_FLAG_TOKEN,
  IN_GAME_AD_FEATURE_FLAG_TOKEN,
  KIKKZ_APP_FEATURE_FLAG_TOKEN,
  LANGUAGE_FEATURE_FLAG_TOKEN,
  MIXED_ZONE_FEATURE_FLAG_TOKEN,
  NATIONS_FEATURE_FLAG_TOKEN,
  NOTIFICATION_FEATURE_FLAG_TOKEN,
  NOTIFICATIONS_DEBUG_INFORMATION_FEATURE_FLAG_TOKEN,
  RANKING_AD_FEATURE_FLAG_TOKEN,
  TIMER_BAR_IN_PLAYER_SEARCH_FEATURE_FLAG_TOKEN,
  TOPSCORER_FEATURE_FLAG_TOKEN,
  TROPHIES_FEATURE_FLAG_TOKEN,
  TTT_FEATURE_FLAG_TOKEN,
  VS_PRO_FEATURE_FLAG_TOKEN,
  WII_FEATURE_FLAG_TOKEN,
} from '@kiq/client/util/config';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { Auth, getAuth, provideAuth } from '@angular/fire/auth';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { initializeApplication } from './app-initializer';
import { ClientDataAccessUserModule, UserFacade } from '@kiq/client/data-access/user';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { provideLottieOptions } from 'ngx-lottie';
import { environment } from '../environments/environment';
import { ConfigFacade, CustomTitleStrategyService } from '@kiq/client/data-access/config';
import { ModalService } from '@kiq/shared/util/modal';
import { AppAuthenticationService, AUTH_SERVICE, AuthenticationService } from '@kiq/shared/data-access/authentication';
import { NativeAppsService } from '@kiq/shared/data-access/native-apps';
import { LanguageInitializerService, TranslocoConfig } from '@kiq/shared/util/translation';
import { TranslocoService } from '@jsverse/transloco';

import { SharedDataAccessTranslationSettingsModule } from 'shared/data-access/translation-settings';
import { AuthInterceptorService, LanguageInterceptor } from '@kiq/client/feature/shell';
import {
  AppNotificationService,
  NOTIFICATION_SERVICE,
  NotificationService,
  SharedDataAccessNotificationModule,
} from 'shared/data-access/notification';
import { AngularFireRemoteConfigModule } from '@angular/fire/compat/remote-config';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import { ClientDataAccessRemoteConfigModule, RemoteConfigFacade } from '@kiq/client/data-access/remote-config';
import { AdvertisementFacade, ClientDataAccessAdvertisementModule } from '@kiq/client/data-access/advertisement';
import { ASSET_TRANSFORMER, AssetsHelperService } from '@kiq/shared/util/asset-helper';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking', scrollPositionRestoration: 'enabled' }),
    StoreModule.forRoot(
      {},
      {
        metaReducers: [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      },
    ),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: false,
      logOnly: !isDevMode(),
      connectInZone: true,
      actionsBlocklist: ['time tick'],
    }),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    provideRemoteConfig(() => {
      const remoteConfig = getRemoteConfig();
      remoteConfig.settings = {
        ...remoteConfig.settings,
        minimumFetchIntervalMillis: 10000,
      };
      return remoteConfig;
    }),
    AngularFireRemoteConfigModule,
    ClientDataAccessUserModule,
    ClientDataAccessRemoteConfigModule,
    SharedDataAccessTranslationSettingsModule,
    SharedDataAccessNotificationModule,
    ClientDataAccessAdvertisementModule,
  ],
  providers: [
    {
      provide: AUTH_SERVICE,
      useFactory: () => {
        const isInNativeApp =
          navigator.userAgent.includes('-KIKKZ-app-android') || navigator.userAgent.includes('-KIKKZ-app-ios');
        if (isInNativeApp) {
          return new AppAuthenticationService();
        } else return new AuthenticationService();
      },
      deps: [NativeAppsService, ConfigFacade, Auth, UserFacade, ModalService],
    },
    {
      provide: NOTIFICATION_SERVICE,
      useFactory: () => {
        const isInNativeApp =
          navigator.userAgent.includes('-KIKKZ-app-android') || navigator.userAgent.includes('-KIKKZ-app-ios');
        if (isInNativeApp) {
          return new AppNotificationService();
        } else return new NotificationService();
      },
      deps: [NativeAppsService, ConfigFacade, Auth, UserFacade, ModalService],
    },
    { provide: BACKEND_TOKEN, useValue: environment.API.BASE },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApplication,
      deps: [Actions, UserFacade, Auth, AUTH_SERVICE, RemoteConfigFacade, AdvertisementFacade, NOTIFICATION_SERVICE],
      multi: true,
    },
    {
      provide: LanguageInitializerService,
      useFactory: (translocoService: TranslocoService, configFacade: ConfigFacade) => {
        const languageFeatureFlag = configFacade.languageFeatureFlag();
        return new LanguageInitializerService(translocoService, languageFeatureFlag);
      },
      deps: [TranslocoService, ConfigFacade],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (languageInitializer: LanguageInitializerService) => () => languageInitializer.initializeLanguage(),
      deps: [LanguageInitializerService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (assetsHelperService: AssetsHelperService) => () => assetsHelperService.initHashedAssetsMap$(),
      deps: [AssetsHelperService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (nativeAppsService: NativeAppsService, configFacade: ConfigFacade) => () => {
        try {
          const capabilities = nativeAppsService.getCapabilities();

          if (capabilities) {
            configFacade.setCapabilities(capabilities);
          }
        } catch (e) {
          console.error('Error getting capabilities', e);
        }
      },
      deps: [NativeAppsService, ConfigFacade],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },

    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    {
      provide: TitleStrategy,
      useClass: CustomTitleStrategyService,
    },
    { provide: TTT_FEATURE_FLAG_TOKEN, useValue: environment.API.TTT_FEATURE_FLAG },
    { provide: WII_FEATURE_FLAG_TOKEN, useValue: environment.API.WII_FEATURE_FLAG },
    { provide: TOPSCORER_FEATURE_FLAG_TOKEN, useValue: environment.API.TOPSCORER_FEATURE_FLAG },
    { provide: MIXED_ZONE_FEATURE_FLAG_TOKEN, useValue: environment.API.MIXED_ZONE_FEATURE_FLAG_TOKEN },
    { provide: FAKE_OR_FACT_FEATURE_FLAG_TOKEN, useValue: environment.API.FAKE_OR_FACT_FEATURE_FLAG_TOKEN },
    { provide: RANKING_AD_FEATURE_FLAG_TOKEN, useValue: environment.API.AD.RANKING_AD_FEATURE_FLAG },
    { provide: IN_GAME_AD_FEATURE_FLAG_TOKEN, useValue: environment.API.AD.IN_GAME_AD_FEATURE_FLAG },
    { provide: GAMES_OVERVIEW_AD_FEATURE_FLAG_TOKEN, useValue: environment.API.AD.GAMES_OVERVIEW_AD_FEATURE_FLAG },
    { provide: NATIONS_FEATURE_FLAG_TOKEN, useValue: environment.API.NATIONS_FEATURE_FLAG },
    { provide: BULI_LICENSE_FEATURE_FLAG_TOKEN, useValue: environment.API.BULI_LICENSE_FEATURE_FLAG },
    { provide: TROPHIES_FEATURE_FLAG_TOKEN, useValue: environment.API.TROPHIES_FEATURE_FLAG },
    { provide: ASYNC_GAMES_TOKEN, useValue: environment.API.ASYNCHRON_FEATURE_FLAG },
    { provide: LANGUAGE_FEATURE_FLAG_TOKEN, useValue: environment.API.LANGUAGE_FEATURE_FLAG },
    { provide: NOTIFICATION_FEATURE_FLAG_TOKEN, useValue: environment.API.NOTIFICATION_FEATURE_FLAG },
    {
      provide: NOTIFICATIONS_DEBUG_INFORMATION_FEATURE_FLAG_TOKEN,
      useValue: environment.API.NOTIFICATIONS_DEBUG_INFORMATION_FEATURE_FLAG,
    },
    { provide: KIKKZ_APP_FEATURE_FLAG_TOKEN, useValue: environment.API.KIKKZ_APP_FEATURE_FLAG },
    { provide: DATA_SINCE_1990_FEATURE_FLAG_TOKEN, useValue: environment.API.DATA_SINCE_1990_FEATURE_FLAG },
    {
      provide: TIMER_BAR_IN_PLAYER_SEARCH_FEATURE_FLAG_TOKEN,
      useValue: environment.API.TIMER_BAR_IN_PLAYER_SEARCH_FEATURE_FLAG,
    },
    {
      provide: VS_PRO_FEATURE_FLAG_TOKEN,
      useValue: environment.API.VS_PRO_FEATURE_FLAG,
    },
    AssetsHelperService,
    TranslocoConfig(),
    TranslocoService,
    {
      provide: ASSET_TRANSFORMER,
      useExisting: AssetsHelperService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
