import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { NativeAppType, ViewMode, ViewSize } from '@kiq/shared/enums';
import { computed, inject } from '@angular/core';
import { ViewportService } from '../service/viewport.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ASYNC_GAMES_TOKEN,
  BULI_LICENSE_FEATURE_FLAG_TOKEN,
  DATA_SINCE_1990_FEATURE_FLAG_TOKEN,
  FAKE_OR_FACT_FEATURE_FLAG_TOKEN,
  GAMES_OVERVIEW_AD_FEATURE_FLAG_TOKEN,
  IN_GAME_AD_FEATURE_FLAG_TOKEN,
  KIKKZ_APP_FEATURE_FLAG_TOKEN,
  LANGUAGE_FEATURE_FLAG_TOKEN,
  MIXED_ZONE_FEATURE_FLAG_TOKEN,
  NATIONS_FEATURE_FLAG_TOKEN,
  NOTIFICATION_FEATURE_FLAG_TOKEN,
  NOTIFICATIONS_DEBUG_INFORMATION_FEATURE_FLAG_TOKEN,
  RANKING_AD_FEATURE_FLAG_TOKEN,
  TIMER_BAR_IN_PLAYER_SEARCH_FEATURE_FLAG_TOKEN,
  TOPSCORER_FEATURE_FLAG_TOKEN,
  TROPHIES_FEATURE_FLAG_TOKEN,
  TTT_FEATURE_FLAG_TOKEN,
  VS_PRO_FEATURE_FLAG_TOKEN,
  WII_FEATURE_FLAG_TOKEN,
} from '@kiq/client/util/config';

type ConfigState = {
  viewSize: ViewSize;
  viewMode: ViewMode;
  useFullscreenModal: boolean;
  headerVisible: boolean;
  test: {
    test2: string;
  };
  partner?: string;
  tttFeature: boolean;
  wiiFeature: boolean;
  topscorerFeature: boolean;
  mixedZoneFeature: boolean;
  fakeOrFactFeature: boolean;
  adRankingFeature: boolean;
  adInGameFeature: boolean;
  adGamesOverviewFeature: boolean;
  nationsFeature: boolean;
  nativeAppType: NativeAppType | null;
  buliLicenseFeature: boolean;
  trophiesFeature: boolean;
  asynchroneFeature: boolean;
  languageFeature: boolean;
  notificationFeature: boolean;
  notificationDebugInformationFeature: boolean;
  kikkzAppFeature: boolean;
  dataSince1990FeatureFlag: boolean;
  timerBarInPlayerSearchFeatureFlag: boolean;
  vsProFeatureFlag: boolean;
  profileImgUploadInNativeAppFeatureFlag: boolean;
};

const initialState: ConfigState = {
  viewSize: ViewSize.Desktop,
  viewMode: ViewMode.Landscape,
  useFullscreenModal: true,
  headerVisible: true,
  test: {
    test2: 'test',
  },
  partner: undefined,
  tttFeature: true,
  wiiFeature: true,
  topscorerFeature: true,
  mixedZoneFeature: true,
  fakeOrFactFeature: true,
  adRankingFeature: true,
  adInGameFeature: true,
  adGamesOverviewFeature: true,
  nationsFeature: true,
  nativeAppType: null,
  buliLicenseFeature: false,
  trophiesFeature: false,
  asynchroneFeature: false,
  languageFeature: false,
  notificationFeature: false,
  notificationDebugInformationFeature: false,
  kikkzAppFeature: false,
  dataSince1990FeatureFlag: false,
  timerBarInPlayerSearchFeatureFlag: false,
  vsProFeatureFlag: false,
  profileImgUploadInNativeAppFeatureFlag: false,
};

const CAPABILITY_PROFILE_IMG_UPLOAD = 'uploadUserImage';

export const ConfigStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(({ viewSize, viewMode, test, nativeAppType, profileImgUploadInNativeAppFeatureFlag }) => {
    return {
      isMobile: computed(() => viewSize() === ViewSize.Mobile),
      isTablet: computed(() => viewSize() === ViewSize.Tablet),
      isDesktop: computed(() => viewSize() === ViewSize.Desktop),
      isLandscape: computed(() => viewMode() === ViewMode.Landscape),
      isPortrait: computed(() => viewMode() === ViewMode.Portrait),
      profileImgUploadEnabled: computed(
        () => !nativeAppType() || (nativeAppType() && profileImgUploadInNativeAppFeatureFlag()),
      ),
    };
  }),
  withMethods((store) => {
    return {
      setViewSize(viewSize: ViewSize) {
        patchState(store, { viewSize });
      },
      setViewMode(viewMode: ViewMode) {
        patchState(store, { viewMode });
      },
      setUseFullscreenModal(useFullscreenModal: boolean) {
        patchState(store, { useFullscreenModal: useFullscreenModal });
      },
      setHeaderVisibility(visible: boolean) {
        patchState(store, { headerVisible: visible });
      },
      setPartner(partner?: string) {
        patchState(store, { partner: partner });
      },
      setTTTFeature(tttFeature: boolean) {
        patchState(store, { tttFeature });
      },
      setWIIFeature(wiiFeature: boolean) {
        patchState(store, { wiiFeature });
      },
      setTopscorerFeature(topscorerFeature: boolean) {
        patchState(store, { topscorerFeature });
      },
      setMixedZoneFeature(mixedZoneFeature: boolean) {
        patchState(store, { mixedZoneFeature });
      },
      setFakeOrFactFeature(fakeOrFactFeature: boolean) {
        patchState(store, { fakeOrFactFeature });
      },
      setAdRankingFeature(adRankingFeature: boolean) {
        patchState(store, { adRankingFeature });
      },
      setAdInGameFeatureFeature(adInGameFeature: boolean) {
        patchState(store, { adInGameFeature });
      },
      setAdGamesOverviewFeature(adGamesOverviewFeature: boolean) {
        patchState(store, { adGamesOverviewFeature });
      },
      setNationsFeature(nationsFeature: boolean) {
        patchState(store, { nationsFeature });
      },
      setNativeAppType(nativeAppType: NativeAppType | null) {
        patchState(store, { nativeAppType });
      },
      setBuliLicenseFeature(buliLicenseFeature: boolean) {
        patchState(store, { buliLicenseFeature });
      },
      setTrophiesFeature(trophiesFeature: boolean) {
        patchState(store, { trophiesFeature });
      },
      setAsynchroneFeature(asynchroneFeature: boolean) {
        patchState(store, { asynchroneFeature });
      },
      setLanguageFeature(languageFeature: boolean) {
        patchState(store, { languageFeature });
      },
      setNotificationFeature(notificationFeature: boolean) {
        patchState(store, { notificationFeature });
      },
      setNotificationDebugInformationFeature(notificationDebugInformationFeature: boolean) {
        patchState(store, { notificationDebugInformationFeature });
      },
      setKikkzAppFeature(kikkzAppFeature: boolean) {
        patchState(store, { kikkzAppFeature });
      },
      setDataSince1990Feature(dataSince1990FeatureFlag: boolean) {
        patchState(store, { dataSince1990FeatureFlag });
      },
      setTimerBarInPlayerSearchFeature(timerBarInPlayerSearchFeatureFlag: boolean) {
        patchState(store, { timerBarInPlayerSearchFeatureFlag });
      },
      setVsProFeature(vsProFeatureFlag: boolean) {
        patchState(store, { vsProFeatureFlag });
      },
      setCapabilities(capabilities: string[]) {
        const profileImgUploadInNativeAppFeatureFlag = capabilities.includes(CAPABILITY_PROFILE_IMG_UPLOAD);

        patchState(store, { profileImgUploadInNativeAppFeatureFlag });
      },
    };
  }),
  withHooks(
    (
      store,
      viewportService = inject(ViewportService),
      tttFeature = inject(TTT_FEATURE_FLAG_TOKEN),
      wiiFeature = inject(WII_FEATURE_FLAG_TOKEN),
      topscorerFeature = inject(TOPSCORER_FEATURE_FLAG_TOKEN),
      mixedZoneFeature = inject(MIXED_ZONE_FEATURE_FLAG_TOKEN),
      fakeOrFactFeature = inject(FAKE_OR_FACT_FEATURE_FLAG_TOKEN),
      adRankingFeature = inject(RANKING_AD_FEATURE_FLAG_TOKEN),
      adInGameFeature = inject(IN_GAME_AD_FEATURE_FLAG_TOKEN),
      adGamesOverviewFeature = inject(GAMES_OVERVIEW_AD_FEATURE_FLAG_TOKEN),
      nationsFeature = inject(NATIONS_FEATURE_FLAG_TOKEN),
      buliLicenseFeature = inject(BULI_LICENSE_FEATURE_FLAG_TOKEN),
      trophiesFeature = inject(TROPHIES_FEATURE_FLAG_TOKEN),
      asynchroneFeature = inject(ASYNC_GAMES_TOKEN),
      languageFeature = inject(LANGUAGE_FEATURE_FLAG_TOKEN),
      notificationFeature = inject(NOTIFICATION_FEATURE_FLAG_TOKEN),
      notificationDebugInformationFeature = inject(NOTIFICATIONS_DEBUG_INFORMATION_FEATURE_FLAG_TOKEN),
      kikkzAppFeature = inject(KIKKZ_APP_FEATURE_FLAG_TOKEN),
      dataSince1990Feature = inject(DATA_SINCE_1990_FEATURE_FLAG_TOKEN),
      timerBarInPlayerSearchFeature = inject(TIMER_BAR_IN_PLAYER_SEARCH_FEATURE_FLAG_TOKEN),
      vsProFeature = inject(VS_PRO_FEATURE_FLAG_TOKEN),
    ) => {
      return {
        onInit() {
          store.setTTTFeature(tttFeature);
          store.setWIIFeature(wiiFeature);
          store.setTopscorerFeature(topscorerFeature);
          store.setMixedZoneFeature(mixedZoneFeature);
          store.setFakeOrFactFeature(fakeOrFactFeature);
          store.setAdRankingFeature(adRankingFeature);
          store.setAdInGameFeatureFeature(adInGameFeature);
          store.setAdGamesOverviewFeature(adGamesOverviewFeature);
          store.setNationsFeature(nationsFeature);
          let nativeAppType: NativeAppType | null = null;
          if (navigator.userAgent.includes('-KIKKZ-app-android')) {
            nativeAppType = NativeAppType.AndroidApp;
          }
          if (navigator.userAgent.includes('-KIKKZ-app-ios')) {
            nativeAppType = NativeAppType.IosApp;
          }
          store.setNativeAppType(nativeAppType);
          store.setBuliLicenseFeature(buliLicenseFeature);
          store.setTrophiesFeature(trophiesFeature);
          store.setAsynchroneFeature(asynchroneFeature);
          store.setLanguageFeature(languageFeature);
          store.setNotificationFeature(notificationFeature);
          store.setNotificationDebugInformationFeature(notificationDebugInformationFeature);
          store.setKikkzAppFeature(kikkzAppFeature);
          store.setDataSince1990Feature(dataSince1990Feature);
          store.setTimerBarInPlayerSearchFeature(timerBarInPlayerSearchFeature);
          store.setVsProFeature(vsProFeature);

          viewportService.isMobile$
            .pipe(takeUntilDestroyed())
            .subscribe({ next: () => store.setViewSize(ViewSize.Mobile) });
          viewportService.isTablet$
            .pipe(takeUntilDestroyed())
            .subscribe({ next: () => store.setViewSize(ViewSize.Tablet) });
          viewportService.isDesktop$
            .pipe(takeUntilDestroyed())
            .subscribe({ next: () => store.setViewSize(ViewSize.Desktop) });
          viewportService.isLandscape$
            .pipe(takeUntilDestroyed())
            .subscribe({ next: () => store.setViewMode(ViewMode.Landscape) });
          viewportService.isPortrait$
            .pipe(takeUntilDestroyed())
            .subscribe({ next: () => store.setViewMode(ViewMode.Portrait) });
          viewportService.useFullscreenModal$
            .pipe(takeUntilDestroyed())
            .subscribe({ next: (result) => store.setUseFullscreenModal(result) });
        },
      };
    },
  ),
);
