// Auto-generated file. Do not edit manually.

import { AdvertisementPlace, AdvertisementType, ViewSize } from '@kiq/shared/enums';
import { AdvertisementAsset } from '@kiq/shared/types';

export const generalAdvertisementAssets: Map<AdvertisementPlace, AdvertisementAsset[]> = new Map([
  
      ["gamesOverview" as AdvertisementPlace, [
  {
    "clubName": null,
    "id": "Adidas-gamesOverview-0",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3648061&v=77004&q=486312&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3648061&v=77004&q=486312&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3648061&v=77004&q=486312&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3648061&v=77004&q=486312&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3648061&v=77004&q=486312&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3648061&v=77004&q=486312&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-gamesOverview-1",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3756084&v=77004&q=487610&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3756084&v=77004&q=487610&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3756084&v=77004&q=487610&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3756084&v=77004&q=487610&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3756084&v=77004&q=487610&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3756084&v=77004&q=487610&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-gamesOverview-2",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Farsenal-24-25-ausweichtrikot%2FIZ0114.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/aba2d03140364ed1bd6080ced9769796_9366/Arsenal_24-25_Ausweichtrikot_Blau_IZ0114_HM4.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Farsenal-24-25-ausweichtrikot%2FIZ0114.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/aba2d03140364ed1bd6080ced9769796_9366/Arsenal_24-25_Ausweichtrikot_Blau_IZ0114_HM4.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Farsenal-24-25-ausweichtrikot%2FIZ0114.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/aba2d03140364ed1bd6080ced9769796_9366/Arsenal_24-25_Ausweichtrikot_Blau_IZ0114_HM4.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-gamesOverview-3",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fjuventus-turin-24-25-ausweichtrikot%2FIY5244.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/50b7aafef6244691bcf7faa9db3b0409_9366/Juventus_Turin_24-25_Ausweichtrikot_Blau_IY5244_HM6.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fjuventus-turin-24-25-ausweichtrikot%2FIY5244.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/50b7aafef6244691bcf7faa9db3b0409_9366/Juventus_Turin_24-25_Ausweichtrikot_Blau_IY5244_HM6.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fjuventus-turin-24-25-ausweichtrikot%2FIY5244.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/50b7aafef6244691bcf7faa9db3b0409_9366/Juventus_Turin_24-25_Ausweichtrikot_Blau_IY5244_HM6.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-gamesOverview-4",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fmanchester-united-24-25-ausweichtrikot%2FIY7806.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/d4bf341cadb64d62b7a678da392293b2_9366/Manchester_United_24-25_Ausweichtrikot_Weiss_IY7806_HM5.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fmanchester-united-24-25-ausweichtrikot%2FIY7806.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/d4bf341cadb64d62b7a678da392293b2_9366/Manchester_United_24-25_Ausweichtrikot_Weiss_IY7806_HM5.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fmanchester-united-24-25-ausweichtrikot%2FIY7806.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/d4bf341cadb64d62b7a678da392293b2_9366/Manchester_United_24-25_Ausweichtrikot_Weiss_IY7806_HM5.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-gamesOverview-5",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Freal-madrid-24-25-ausweichtrikot%2FIY1763.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/aea86258390b4582a2c4a800c45da530_9366/Real_Madrid_24-25_Ausweichtrikot_Braun_IY1763_HM4.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Freal-madrid-24-25-ausweichtrikot%2FIY1763.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/aea86258390b4582a2c4a800c45da530_9366/Real_Madrid_24-25_Ausweichtrikot_Braun_IY1763_HM4.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Freal-madrid-24-25-ausweichtrikot%2FIY1763.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/aea86258390b4582a2c4a800c45da530_9366/Real_Madrid_24-25_Ausweichtrikot_Braun_IY1763_HM4.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-gamesOverview-6",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fceltic-fc-24-25-heimtrikot%2FIX7863.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/1fd86afb28b74d559baac6acee12437c_9366/Celtic_FC_24-25_Heimtrikot_Weiss_IX7863_HM8.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fceltic-fc-24-25-heimtrikot%2FIX7863.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/1fd86afb28b74d559baac6acee12437c_9366/Celtic_FC_24-25_Heimtrikot_Weiss_IX7863_HM8.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fceltic-fc-24-25-heimtrikot%2FIX7863.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/1fd86afb28b74d559baac6acee12437c_9366/Celtic_FC_24-25_Heimtrikot_Weiss_IX7863_HM8.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-gamesOverview-7",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fas-rom-bring-back-1993-trikot%2FIT3871.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/691328eeadb445348c61c080a251f18e_9366/AS_Rom_Bring_Back_1993_Trikot_Weinrot_IT3871_HM5.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fas-rom-bring-back-1993-trikot%2FIT3871.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/691328eeadb445348c61c080a251f18e_9366/AS_Rom_Bring_Back_1993_Trikot_Weinrot_IT3871_HM5.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fas-rom-bring-back-1993-trikot%2FIT3871.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/691328eeadb445348c61c080a251f18e_9366/AS_Rom_Bring_Back_1993_Trikot_Weinrot_IT3871_HM5.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-gamesOverview-8",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fnewcastle-united-fc-lfstlr-trikot%2FIX5156.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/77b717b2f2be45ce8d2a03d0ffc49c97_9366/Newcastle_United_FC_LFSTLR_Trikot_Weiss_IX5156_41_detail.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fnewcastle-united-fc-lfstlr-trikot%2FIX5156.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/77b717b2f2be45ce8d2a03d0ffc49c97_9366/Newcastle_United_FC_LFSTLR_Trikot_Weiss_IX5156_41_detail.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fnewcastle-united-fc-lfstlr-trikot%2FIX5156.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/77b717b2f2be45ce8d2a03d0ffc49c97_9366/Newcastle_United_FC_LFSTLR_Trikot_Weiss_IX5156_41_detail.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-gamesOverview-9",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fargentinien-anniversary-trikot%2FJF2641.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/f04373811f534de19a08b118e3e6828f_9366/Argentinien_Anniversary_Trikot_Blau_JF2641_HM5.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fargentinien-anniversary-trikot%2FJF2641.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/f04373811f534de19a08b118e3e6828f_9366/Argentinien_Anniversary_Trikot_Blau_JF2641_HM5.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fargentinien-anniversary-trikot%2FJF2641.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/f04373811f534de19a08b118e3e6828f_9366/Argentinien_Anniversary_Trikot_Blau_JF2641_HM5.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-gamesOverview-10",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fargentinien-1994-auswartstrikot%2FIS0266.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/b3e803ecc72c41ae826da1573d2e297d_9366/Argentinien_1994_Auswartstrikot_Blau_IS0266_HM4.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fargentinien-1994-auswartstrikot%2FIS0266.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/b3e803ecc72c41ae826da1573d2e297d_9366/Argentinien_1994_Auswartstrikot_Blau_IS0266_HM4.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fargentinien-1994-auswartstrikot%2FIS0266.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/b3e803ecc72c41ae826da1573d2e297d_9366/Argentinien_1994_Auswartstrikot_Blau_IS0266_HM4.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-gamesOverview-11",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-1996-cotton-t-shirt%2FIL8016.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/711b309144ae4f1eba76a53175302a7d_9366/DFB_1996_Cotton_T-Shirt_Weiss_IL8016_HM8.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-1996-cotton-t-shirt%2FIL8016.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/711b309144ae4f1eba76a53175302a7d_9366/DFB_1996_Cotton_T-Shirt_Weiss_IL8016_HM8.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-1996-cotton-t-shirt%2FIL8016.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/711b309144ae4f1eba76a53175302a7d_9366/DFB_1996_Cotton_T-Shirt_Weiss_IL8016_HM8.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-gamesOverview-12",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-1996-heimtrikot%2FIT7749.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/712667a06a3b4f3eafad81a5ebd21194_9366/DFB_1996_Heimtrikot_Weiss_IT7749_HM4_hover.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-1996-heimtrikot%2FIT7749.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/712667a06a3b4f3eafad81a5ebd21194_9366/DFB_1996_Heimtrikot_Weiss_IT7749_HM4_hover.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-1996-heimtrikot%2FIT7749.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/712667a06a3b4f3eafad81a5ebd21194_9366/DFB_1996_Heimtrikot_Weiss_IT7749_HM4_hover.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-gamesOverview-13",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fspanien-1996-heimtrikot%2FIT7754.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/25927ae16d5249f193dcfca3071eed02_9366/Spanien_1996_Heimtrikot_Rot_IT7754_HM7.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fspanien-1996-heimtrikot%2FIT7754.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/25927ae16d5249f193dcfca3071eed02_9366/Spanien_1996_Heimtrikot_Rot_IT7754_HM7.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fspanien-1996-heimtrikot%2FIT7754.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/25927ae16d5249f193dcfca3071eed02_9366/Spanien_1996_Heimtrikot_Rot_IT7754_HM7.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-gamesOverview-14",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fjamaika-lfstlr-trikot%2FIT2464.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/951256b868e04548b1825f26c5a63a58_9366/Jamaika_LFSTLR_Trikot_Schwarz_IT2464_HM7.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fjamaika-lfstlr-trikot%2FIT2464.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/951256b868e04548b1825f26c5a63a58_9366/Jamaika_LFSTLR_Trikot_Schwarz_IT2464_HM7.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fjamaika-lfstlr-trikot%2FIT2464.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/951256b868e04548b1825f26c5a63a58_9366/Jamaika_LFSTLR_Trikot_Schwarz_IT2464_HM7.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-gamesOverview-15",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Freal-madrid-terrace-icons-drill-oberteil%2FJF2578.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/829d6ef8b7e447758793de4955464e4f_9366/Real_Madrid_Terrace_Icons_Drill_Oberteil_Lila_JF2578_HM6.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Freal-madrid-terrace-icons-drill-oberteil%2FJF2578.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/829d6ef8b7e447758793de4955464e4f_9366/Real_Madrid_Terrace_Icons_Drill_Oberteil_Lila_JF2578_HM6.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Freal-madrid-terrace-icons-drill-oberteil%2FJF2578.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/829d6ef8b7e447758793de4955464e4f_9366/Real_Madrid_Terrace_Icons_Drill_Oberteil_Lila_JF2578_HM6.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-gamesOverview-16",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Freal-madrid-originals-t-shirt%2FIM7467.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/0466f6e34d7644a594c2e359cadfd359_9366/Real_Madrid_Originals_T-Shirt_Rosa_IM7467_HM4.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Freal-madrid-originals-t-shirt%2FIM7467.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/0466f6e34d7644a594c2e359cadfd359_9366/Real_Madrid_Originals_T-Shirt_Rosa_IM7467_HM4.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Freal-madrid-originals-t-shirt%2FIM7467.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/0466f6e34d7644a594c2e359cadfd359_9366/Real_Madrid_Originals_T-Shirt_Rosa_IM7467_HM4.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-gamesOverview-17",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-1996-auswartstrikot%2FIT7751.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/306755ea12dc49baaf0a2e154133bec9_9366/DFB_1996_Auswartstrikot_Grun_IT7751_HM4.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-1996-auswartstrikot%2FIT7751.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/306755ea12dc49baaf0a2e154133bec9_9366/DFB_1996_Auswartstrikot_Grun_IT7751_HM4.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-1996-auswartstrikot%2FIT7751.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/306755ea12dc49baaf0a2e154133bec9_9366/DFB_1996_Auswartstrikot_Grun_IT7751_HM4.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-gamesOverview-18",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-adicolor-classics-3-streifen-t-shirt%2FIU2102.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/441ef951898e4a9cb4c9d6abec5c8bc9_9366/DFB_Adicolor_Classics_3-Streifen_T-Shirt_Weiss_IU2102_HM6.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-adicolor-classics-3-streifen-t-shirt%2FIU2102.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/441ef951898e4a9cb4c9d6abec5c8bc9_9366/DFB_Adicolor_Classics_3-Streifen_T-Shirt_Weiss_IU2102_HM6.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-adicolor-classics-3-streifen-t-shirt%2FIU2102.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/441ef951898e4a9cb4c9d6abec5c8bc9_9366/DFB_Adicolor_Classics_3-Streifen_T-Shirt_Weiss_IU2102_HM6.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "JD-Sports-gamesOverview-38",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3694601&v=10073&q=383605&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694601&v=10073&q=383605&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3694601&v=10073&q=383605&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694601&v=10073&q=383605&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3694601&v=10073&q=383605&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694601&v=10073&q=383605&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "ODLO-gamesOverview-44",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3820951&v=14586&q=508085&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3820951&v=14586&q=508085&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3820951&v=14586&q=508085&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3820951&v=14586&q=508085&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3820951&v=14586&q=508085&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3820951&v=14586&q=508085&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "ODLO-gamesOverview-45",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3816639&v=14586&q=507372&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3816639&v=14586&q=507372&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3816639&v=14586&q=507372&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3816639&v=14586&q=507372&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3816639&v=14586&q=507372&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3816639&v=14586&q=507372&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Rose-Bikes-gamesOverview-46",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3694529&v=11768&q=469428&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694529&v=11768&q=469428&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3694529&v=11768&q=469428&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694529&v=11768&q=469428&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3694529&v=11768&q=469428&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694529&v=11768&q=469428&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Rose-Bikes-gamesOverview-47",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3638031&v=11768&q=484896&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3638031&v=11768&q=484896&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3638031&v=11768&q=484896&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3638031&v=11768&q=484896&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3638031&v=11768&q=484896&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3638031&v=11768&q=484896&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Sportscheck-gamesOverview-51",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2373880&v=14607&q=361910&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2373880&v=14607&q=361910&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2373880&v=14607&q=361910&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2373880&v=14607&q=361910&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2373880&v=14607&q=361910&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2373880&v=14607&q=361910&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
    ,

      ["inGame" as AdvertisementPlace, [
  {
    "clubName": null,
    "id": "Adidas-inGame-19",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3648069&v=77004&q=486312&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3648069&v=77004&q=486312&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3648069&v=77004&q=486312&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3648069&v=77004&q=486312&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3648069&v=77004&q=486312&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3648069&v=77004&q=486312&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-inGame-20",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3756078&v=77004&q=487610&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3756078&v=77004&q=487610&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3756078&v=77004&q=487610&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3756078&v=77004&q=487610&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3756078&v=77004&q=487610&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3756078&v=77004&q=487610&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-inGame-21",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Farsenal-24-25-ausweichtrikot%2FIZ0114.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/aba2d03140364ed1bd6080ced9769796_9366/Arsenal_24-25_Ausweichtrikot_Blau_IZ0114_HM4.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Farsenal-24-25-ausweichtrikot%2FIZ0114.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/aba2d03140364ed1bd6080ced9769796_9366/Arsenal_24-25_Ausweichtrikot_Blau_IZ0114_HM4.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Farsenal-24-25-ausweichtrikot%2FIZ0114.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/aba2d03140364ed1bd6080ced9769796_9366/Arsenal_24-25_Ausweichtrikot_Blau_IZ0114_HM4.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-inGame-22",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fjuventus-turin-24-25-ausweichtrikot%2FIY5244.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/50b7aafef6244691bcf7faa9db3b0409_9366/Juventus_Turin_24-25_Ausweichtrikot_Blau_IY5244_HM6.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fjuventus-turin-24-25-ausweichtrikot%2FIY5244.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/50b7aafef6244691bcf7faa9db3b0409_9366/Juventus_Turin_24-25_Ausweichtrikot_Blau_IY5244_HM6.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fjuventus-turin-24-25-ausweichtrikot%2FIY5244.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/50b7aafef6244691bcf7faa9db3b0409_9366/Juventus_Turin_24-25_Ausweichtrikot_Blau_IY5244_HM6.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-inGame-23",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fmanchester-united-24-25-ausweichtrikot%2FIY7806.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/d4bf341cadb64d62b7a678da392293b2_9366/Manchester_United_24-25_Ausweichtrikot_Weiss_IY7806_HM5.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fmanchester-united-24-25-ausweichtrikot%2FIY7806.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/d4bf341cadb64d62b7a678da392293b2_9366/Manchester_United_24-25_Ausweichtrikot_Weiss_IY7806_HM5.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fmanchester-united-24-25-ausweichtrikot%2FIY7806.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/d4bf341cadb64d62b7a678da392293b2_9366/Manchester_United_24-25_Ausweichtrikot_Weiss_IY7806_HM5.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-inGame-24",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Freal-madrid-24-25-ausweichtrikot%2FIY1763.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/aea86258390b4582a2c4a800c45da530_9366/Real_Madrid_24-25_Ausweichtrikot_Braun_IY1763_HM4.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Freal-madrid-24-25-ausweichtrikot%2FIY1763.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/aea86258390b4582a2c4a800c45da530_9366/Real_Madrid_24-25_Ausweichtrikot_Braun_IY1763_HM4.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Freal-madrid-24-25-ausweichtrikot%2FIY1763.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/aea86258390b4582a2c4a800c45da530_9366/Real_Madrid_24-25_Ausweichtrikot_Braun_IY1763_HM4.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-inGame-25",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fceltic-fc-24-25-heimtrikot%2FIX7863.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/1fd86afb28b74d559baac6acee12437c_9366/Celtic_FC_24-25_Heimtrikot_Weiss_IX7863_HM8.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fceltic-fc-24-25-heimtrikot%2FIX7863.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/1fd86afb28b74d559baac6acee12437c_9366/Celtic_FC_24-25_Heimtrikot_Weiss_IX7863_HM8.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fceltic-fc-24-25-heimtrikot%2FIX7863.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/1fd86afb28b74d559baac6acee12437c_9366/Celtic_FC_24-25_Heimtrikot_Weiss_IX7863_HM8.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-inGame-26",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fas-rom-bring-back-1993-trikot%2FIT3871.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/691328eeadb445348c61c080a251f18e_9366/AS_Rom_Bring_Back_1993_Trikot_Weinrot_IT3871_HM5.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fas-rom-bring-back-1993-trikot%2FIT3871.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/691328eeadb445348c61c080a251f18e_9366/AS_Rom_Bring_Back_1993_Trikot_Weinrot_IT3871_HM5.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fas-rom-bring-back-1993-trikot%2FIT3871.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/691328eeadb445348c61c080a251f18e_9366/AS_Rom_Bring_Back_1993_Trikot_Weinrot_IT3871_HM5.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-inGame-27",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fnewcastle-united-fc-lfstlr-trikot%2FIX5156.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/77b717b2f2be45ce8d2a03d0ffc49c97_9366/Newcastle_United_FC_LFSTLR_Trikot_Weiss_IX5156_41_detail.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fnewcastle-united-fc-lfstlr-trikot%2FIX5156.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/77b717b2f2be45ce8d2a03d0ffc49c97_9366/Newcastle_United_FC_LFSTLR_Trikot_Weiss_IX5156_41_detail.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fnewcastle-united-fc-lfstlr-trikot%2FIX5156.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/77b717b2f2be45ce8d2a03d0ffc49c97_9366/Newcastle_United_FC_LFSTLR_Trikot_Weiss_IX5156_41_detail.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-inGame-28",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fargentinien-anniversary-trikot%2FJF2641.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/f04373811f534de19a08b118e3e6828f_9366/Argentinien_Anniversary_Trikot_Blau_JF2641_HM5.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fargentinien-anniversary-trikot%2FJF2641.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/f04373811f534de19a08b118e3e6828f_9366/Argentinien_Anniversary_Trikot_Blau_JF2641_HM5.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fargentinien-anniversary-trikot%2FJF2641.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/f04373811f534de19a08b118e3e6828f_9366/Argentinien_Anniversary_Trikot_Blau_JF2641_HM5.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-inGame-29",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fargentinien-1994-auswartstrikot%2FIS0266.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/b3e803ecc72c41ae826da1573d2e297d_9366/Argentinien_1994_Auswartstrikot_Blau_IS0266_HM4.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fargentinien-1994-auswartstrikot%2FIS0266.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/b3e803ecc72c41ae826da1573d2e297d_9366/Argentinien_1994_Auswartstrikot_Blau_IS0266_HM4.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fargentinien-1994-auswartstrikot%2FIS0266.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/b3e803ecc72c41ae826da1573d2e297d_9366/Argentinien_1994_Auswartstrikot_Blau_IS0266_HM4.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-inGame-30",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-1996-cotton-t-shirt%2FIL8016.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/711b309144ae4f1eba76a53175302a7d_9366/DFB_1996_Cotton_T-Shirt_Weiss_IL8016_HM8.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-1996-cotton-t-shirt%2FIL8016.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/711b309144ae4f1eba76a53175302a7d_9366/DFB_1996_Cotton_T-Shirt_Weiss_IL8016_HM8.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-1996-cotton-t-shirt%2FIL8016.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/711b309144ae4f1eba76a53175302a7d_9366/DFB_1996_Cotton_T-Shirt_Weiss_IL8016_HM8.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-inGame-31",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-1996-heimtrikot%2FIT7749.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/712667a06a3b4f3eafad81a5ebd21194_9366/DFB_1996_Heimtrikot_Weiss_IT7749_HM4_hover.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-1996-heimtrikot%2FIT7749.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/712667a06a3b4f3eafad81a5ebd21194_9366/DFB_1996_Heimtrikot_Weiss_IT7749_HM4_hover.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-1996-heimtrikot%2FIT7749.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/712667a06a3b4f3eafad81a5ebd21194_9366/DFB_1996_Heimtrikot_Weiss_IT7749_HM4_hover.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-inGame-32",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fspanien-1996-heimtrikot%2FIT7754.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/25927ae16d5249f193dcfca3071eed02_9366/Spanien_1996_Heimtrikot_Rot_IT7754_HM7.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fspanien-1996-heimtrikot%2FIT7754.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/25927ae16d5249f193dcfca3071eed02_9366/Spanien_1996_Heimtrikot_Rot_IT7754_HM7.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fspanien-1996-heimtrikot%2FIT7754.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/25927ae16d5249f193dcfca3071eed02_9366/Spanien_1996_Heimtrikot_Rot_IT7754_HM7.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-inGame-33",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fjamaika-lfstlr-trikot%2FIT2464.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/951256b868e04548b1825f26c5a63a58_9366/Jamaika_LFSTLR_Trikot_Schwarz_IT2464_HM7.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fjamaika-lfstlr-trikot%2FIT2464.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/951256b868e04548b1825f26c5a63a58_9366/Jamaika_LFSTLR_Trikot_Schwarz_IT2464_HM7.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fjamaika-lfstlr-trikot%2FIT2464.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/951256b868e04548b1825f26c5a63a58_9366/Jamaika_LFSTLR_Trikot_Schwarz_IT2464_HM7.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-inGame-34",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Freal-madrid-terrace-icons-drill-oberteil%2FJF2578.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/829d6ef8b7e447758793de4955464e4f_9366/Real_Madrid_Terrace_Icons_Drill_Oberteil_Lila_JF2578_HM6.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Freal-madrid-terrace-icons-drill-oberteil%2FJF2578.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/829d6ef8b7e447758793de4955464e4f_9366/Real_Madrid_Terrace_Icons_Drill_Oberteil_Lila_JF2578_HM6.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Freal-madrid-terrace-icons-drill-oberteil%2FJF2578.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/829d6ef8b7e447758793de4955464e4f_9366/Real_Madrid_Terrace_Icons_Drill_Oberteil_Lila_JF2578_HM6.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-inGame-35",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Freal-madrid-originals-t-shirt%2FIM7467.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/0466f6e34d7644a594c2e359cadfd359_9366/Real_Madrid_Originals_T-Shirt_Rosa_IM7467_HM4.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Freal-madrid-originals-t-shirt%2FIM7467.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/0466f6e34d7644a594c2e359cadfd359_9366/Real_Madrid_Originals_T-Shirt_Rosa_IM7467_HM4.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Freal-madrid-originals-t-shirt%2FIM7467.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/0466f6e34d7644a594c2e359cadfd359_9366/Real_Madrid_Originals_T-Shirt_Rosa_IM7467_HM4.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-inGame-36",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-1996-auswartstrikot%2FIT7751.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/306755ea12dc49baaf0a2e154133bec9_9366/DFB_1996_Auswartstrikot_Grun_IT7751_HM4.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-1996-auswartstrikot%2FIT7751.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/306755ea12dc49baaf0a2e154133bec9_9366/DFB_1996_Auswartstrikot_Grun_IT7751_HM4.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-1996-auswartstrikot%2FIT7751.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/306755ea12dc49baaf0a2e154133bec9_9366/DFB_1996_Auswartstrikot_Grun_IT7751_HM4.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-inGame-37",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-adicolor-classics-3-streifen-t-shirt%2FIU2102.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/441ef951898e4a9cb4c9d6abec5c8bc9_9366/DFB_Adicolor_Classics_3-Streifen_T-Shirt_Weiss_IU2102_HM6.jpg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-adicolor-classics-3-streifen-t-shirt%2FIU2102.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/441ef951898e4a9cb4c9d6abec5c8bc9_9366/DFB_Adicolor_Classics_3-Streifen_T-Shirt_Weiss_IU2102_HM6.jpg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=77004&awinaffid=1799318&ued=https%3A%2F%2Fwww.adidas.de%2Fdfb-adicolor-classics-3-streifen-t-shirt%2FIU2102.html",
        "imageUrl": "https://assets.adidas.com/images/h_2000,f_auto,q_auto,fl_lossy,c_fill,g_auto/441ef951898e4a9cb4c9d6abec5c8bc9_9366/DFB_Adicolor_Classics_3-Streifen_T-Shirt_Weiss_IU2102_HM6.jpg"
      }
    }
  },
  {
    "clubName": null,
    "id": "JD-Sports-inGame-39",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3694605&v=10073&q=383606&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694605&v=10073&q=383606&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3694605&v=10073&q=383606&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694605&v=10073&q=383606&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3694605&v=10073&q=383606&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694605&v=10073&q=383606&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "JD-Sports-inGame-40",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3629787&v=10073&q=383606&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3629787&v=10073&q=383606&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3629787&v=10073&q=383606&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3629787&v=10073&q=383606&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3629787&v=10073&q=383606&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3629787&v=10073&q=383606&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Rose-Bikes-inGame-48",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3815263&v=11768&q=507618&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815263&v=11768&q=507618&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3815263&v=11768&q=507618&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815263&v=11768&q=507618&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3815263&v=11768&q=507618&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815263&v=11768&q=507618&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Rose-Bikes-inGame-49",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3721332&v=11768&q=469428&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3721332&v=11768&q=469428&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3721332&v=11768&q=469428&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3721332&v=11768&q=469428&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3721332&v=11768&q=469428&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3721332&v=11768&q=469428&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Rose-Bikes-inGame-50",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3694531&v=11768&q=469428&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694531&v=11768&q=469428&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3694531&v=11768&q=469428&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694531&v=11768&q=469428&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3694531&v=11768&q=469428&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694531&v=11768&q=469428&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Thalia-inGame-52",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1050530374",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/bfe82beac8a846a4b001999db920fde0/ich-mag-wenn-s-kracht-taschenbuch-raphael-honigstein.jpeg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1050530374",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/bfe82beac8a846a4b001999db920fde0/ich-mag-wenn-s-kracht-taschenbuch-raphael-honigstein.jpeg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1050530374",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/bfe82beac8a846a4b001999db920fde0/ich-mag-wenn-s-kracht-taschenbuch-raphael-honigstein.jpeg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Thalia-inGame-53",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1050507804",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/982a6b01e6e94e24a754267c4781ea1f/revolutionen-auf-dem-rasen-gebundene-ausgabe-jonathan-wilson.jpeg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1050507804",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/982a6b01e6e94e24a754267c4781ea1f/revolutionen-auf-dem-rasen-gebundene-ausgabe-jonathan-wilson.jpeg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1050507804",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/982a6b01e6e94e24a754267c4781ea1f/revolutionen-auf-dem-rasen-gebundene-ausgabe-jonathan-wilson.jpeg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Thalia-inGame-54",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1011516566",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/c4ff963f32f4484e870e25c59c30595a/die-fussball-matrix-taschenbuch-christoph-biermann.jpeg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1011516566",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/c4ff963f32f4484e870e25c59c30595a/die-fussball-matrix-taschenbuch-christoph-biermann.jpeg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1011516566",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/c4ff963f32f4484e870e25c59c30595a/die-fussball-matrix-taschenbuch-christoph-biermann.jpeg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Thalia-inGame-55",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1038458074",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/8ee542ca73af4738b47a530e9af6dfe1/soccermatics-gebundene-ausgabe-david-sumpter.jpeg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1038458074",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/8ee542ca73af4738b47a530e9af6dfe1/soccermatics-gebundene-ausgabe-david-sumpter.jpeg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1038458074",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/8ee542ca73af4738b47a530e9af6dfe1/soccermatics-gebundene-ausgabe-david-sumpter.jpeg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Thalia-inGame-56",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1000298747",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/3bd8bbf8e5c543b6ae3bb1d079b9dff0/der-traumhueter-taschenbuch-ronald-reng.jpeg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1000298747",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/3bd8bbf8e5c543b6ae3bb1d079b9dff0/der-traumhueter-taschenbuch-ronald-reng.jpeg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1000298747",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/3bd8bbf8e5c543b6ae3bb1d079b9dff0/der-traumhueter-taschenbuch-ronald-reng.jpeg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Thalia-inGame-57",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1063556340",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/b08f937359dd4a419de9fe53185faffb/der-grosse-traum-taschenbuch-ronald-reng.jpeg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1063556340",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/b08f937359dd4a419de9fe53185faffb/der-grosse-traum-taschenbuch-ronald-reng.jpeg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1063556340",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/b08f937359dd4a419de9fe53185faffb/der-grosse-traum-taschenbuch-ronald-reng.jpeg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Thalia-inGame-58",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1072520170",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/469ff2db23c741cc8211057047c5ab61/kay-bernstein-gebundene-ausgabe-sebastian-stier.jpeg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1072520170",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/469ff2db23c741cc8211057047c5ab61/kay-bernstein-gebundene-ausgabe-sebastian-stier.jpeg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1072520170",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/469ff2db23c741cc8211057047c5ab61/kay-bernstein-gebundene-ausgabe-sebastian-stier.jpeg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Thalia-inGame-59",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1033739363",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/6932211fc5b040bca45b0260c3cf163d/ich-bin-zlatan-taschenbuch-zlatan-ibrahimovic.jpeg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1033739363",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/6932211fc5b040bca45b0260c3cf163d/ich-bin-zlatan-taschenbuch-zlatan-ibrahimovic.jpeg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1033739363",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/6932211fc5b040bca45b0260c3cf163d/ich-bin-zlatan-taschenbuch-zlatan-ibrahimovic.jpeg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Thalia-inGame-60",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1068358039",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/c29e851f42674224920afbfcf784ebc9/du-hattest-90-minuten-zeit-taschenbuch-toni-kroos.jpeg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1068358039",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/c29e851f42674224920afbfcf784ebc9/du-hattest-90-minuten-zeit-taschenbuch-toni-kroos.jpeg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1068358039",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/c29e851f42674224920afbfcf784ebc9/du-hattest-90-minuten-zeit-taschenbuch-toni-kroos.jpeg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Thalia-inGame-61",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1063615923",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/a0cc061dabcd46469bc57307e2402070/aus-liebe-zum-spiel-taschenbuch-max-jacob-ost.jpeg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1063615923",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/a0cc061dabcd46469bc57307e2402070/aus-liebe-zum-spiel-taschenbuch-max-jacob-ost.jpeg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1063615923",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/a0cc061dabcd46469bc57307e2402070/aus-liebe-zum-spiel-taschenbuch-max-jacob-ost.jpeg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Thalia-inGame-62",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1060548579",
        "imageUrl": "https://images.thalia.media/00/-/b3a04259895b4c02a2b0d56b697e295a/kaempfen-siegen-leben-ein-leben-fuer-den-fussball-und-gegen-den-krebs-taschenbuch-marco-russ.jpeg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1060548579",
        "imageUrl": "https://images.thalia.media/00/-/b3a04259895b4c02a2b0d56b697e295a/kaempfen-siegen-leben-ein-leben-fuer-den-fussball-und-gegen-den-krebs-taschenbuch-marco-russ.jpeg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1060548579",
        "imageUrl": "https://images.thalia.media/00/-/b3a04259895b4c02a2b0d56b697e295a/kaempfen-siegen-leben-ein-leben-fuer-den-fussball-und-gegen-den-krebs-taschenbuch-marco-russ.jpeg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Thalia-inGame-63",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1071531655",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/46f98be2a8034d2583042d9b65a8f484/immer-auf-m-platz-taschenbuch-hermann-gerland.jpeg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1071531655",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/46f98be2a8034d2583042d9b65a8f484/immer-auf-m-platz-taschenbuch-hermann-gerland.jpeg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1071531655",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/46f98be2a8034d2583042d9b65a8f484/immer-auf-m-platz-taschenbuch-hermann-gerland.jpeg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Thalia-inGame-64",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1070148027",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/ca8918d8788043d29a5f30980b665d33/mario-basler-eigentlich-bin-ich-ein-super-typ-taschenbuch-mario-basler.jpeg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1070148027",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/ca8918d8788043d29a5f30980b665d33/mario-basler-eigentlich-bin-ich-ein-super-typ-taschenbuch-mario-basler.jpeg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1070148027",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/ca8918d8788043d29a5f30980b665d33/mario-basler-eigentlich-bin-ich-ein-super-typ-taschenbuch-mario-basler.jpeg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Thalia-inGame-65",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1059326525",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/d778a74eb99b49edb90f97574558ca17/traeumen-lohnt-sich-mein-etwas-anderer-weg-zum-fussballprofi-epub-robin-gosens.jpeg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1059326525",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/d778a74eb99b49edb90f97574558ca17/traeumen-lohnt-sich-mein-etwas-anderer-weg-zum-fussballprofi-epub-robin-gosens.jpeg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1059326525",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/d778a74eb99b49edb90f97574558ca17/traeumen-lohnt-sich-mein-etwas-anderer-weg-zum-fussballprofi-epub-robin-gosens.jpeg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Thalia-inGame-66",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1059654416",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/aa1c73ce4b3f40c08f99a94d6f9e7e35/weltmeister-ohne-talent-taschenbuch-per-mertesacker.jpeg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1059654416",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/aa1c73ce4b3f40c08f99a94d6f9e7e35/weltmeister-ohne-talent-taschenbuch-per-mertesacker.jpeg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1059654416",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/aa1c73ce4b3f40c08f99a94d6f9e7e35/weltmeister-ohne-talent-taschenbuch-per-mertesacker.jpeg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Thalia-inGame-67",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1055184333",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/b43b0cc5e0ea48b881fe2b82a0fdcb7c/wie-ausgewechselt-taschenbuch-patrick-strasser.jpeg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1055184333",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/b43b0cc5e0ea48b881fe2b82a0fdcb7c/wie-ausgewechselt-taschenbuch-patrick-strasser.jpeg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1055184333",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/b43b0cc5e0ea48b881fe2b82a0fdcb7c/wie-ausgewechselt-taschenbuch-patrick-strasser.jpeg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Thalia-inGame-68",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1070553304",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/2674817010444247bc267d0454d456b3/einer-von-euch-taschenbuch-martin-suter.jpeg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1070553304",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/2674817010444247bc267d0454d456b3/einer-von-euch-taschenbuch-martin-suter.jpeg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1070553304",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/2674817010444247bc267d0454d456b3/einer-von-euch-taschenbuch-martin-suter.jpeg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Thalia-inGame-69",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1057863887",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/217ade9fba6845089a35172a9b272457/miro-taschenbuch-ronald-reng.jpeg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1057863887",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/217ade9fba6845089a35172a9b272457/miro-taschenbuch-ronald-reng.jpeg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1057863887",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/217ade9fba6845089a35172a9b272457/miro-taschenbuch-ronald-reng.jpeg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Thalia-inGame-70",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1069948602",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/e43a54772f304985bbe2608543280375/1974-eine-deutsche-begegnung-gebundene-ausgabe-ronald-reng.jpeg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1069948602",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/e43a54772f304985bbe2608543280375/1974-eine-deutsche-begegnung-gebundene-ausgabe-ronald-reng.jpeg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1069948602",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/e43a54772f304985bbe2608543280375/1974-eine-deutsche-begegnung-gebundene-ausgabe-ronald-reng.jpeg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Thalia-inGame-71",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1040483533",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/59085617ef1d47948d85a5daab2d1c55/barca-epub-ronald-reng.jpeg"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1040483533",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/59085617ef1d47948d85a5daab2d1c55/barca-epub-ronald-reng.jpeg"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?awinmid=14158&awinaffid=1799318&ued=https%3A%2F%2Fwww.thalia.de%2Fshop%2Fhome%2Fartikeldetails%2FA1040483533",
        "imageUrl": "https://images.thalia.media/-/BF2000-2000/59085617ef1d47948d85a5daab2d1c55/barca-epub-ronald-reng.jpeg"
      }
    }
  },
  {
    "clubName": null,
    "id": "Uhlsport-inGame-72",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3665567&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3665567&v=65462&q=475597&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3665567&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3665567&v=65462&q=475597&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3665567&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3665567&v=65462&q=475597&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
    ,

      ["ranking" as AdvertisementPlace, [
  {
    "clubName": null,
    "id": "JD-Sports-ranking-41",
    "placement": "ranking",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3605743&v=10073&q=383620&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3605743&v=10073&q=383620&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3605743&v=10073&q=383620&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3605743&v=10073&q=383620&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3605743&v=10073&q=383620&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3605743&v=10073&q=383620&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "JD-Sports-ranking-42",
    "placement": "ranking",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3629802&v=10073&q=383620&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3629802&v=10073&q=383620&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3629802&v=10073&q=383620&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3629802&v=10073&q=383620&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3629802&v=10073&q=383620&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3629802&v=10073&q=383620&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Myprotein-ranking-43",
    "placement": "ranking",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3203114&v=10700&q=329083&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3203114&v=10700&q=329083&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3203114&v=10700&q=329083&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3203114&v=10700&q=329083&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3203114&v=10700&q=329083&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3203114&v=10700&q=329083&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Uhlsport-ranking-73",
    "placement": "ranking",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3665574&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3665574&v=65462&q=475597&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3665574&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3665574&v=65462&q=475597&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3665574&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3665574&v=65462&q=475597&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Uhlsport-ranking-74",
    "placement": "ranking",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3665566&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3665566&v=65462&q=475597&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3665566&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3665566&v=65462&q=475597&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3665566&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3665566&v=65462&q=475597&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
    
]);

export const clubAdvertisementAssets: Map<string, Map<AdvertisementPlace, AdvertisementAsset[]>> = new Map([
  
      ["Werder Bremen", new Map([
        
          ["gamesOverview" as AdvertisementPlace, [
  {
    "clubName": "Werder-Bremen",
    "id": "Werder-Bremen-gamesOverview-0",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3815889&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815889&v=21339&q=401405&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3815889&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815889&v=21339&q=401405&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3815889&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815889&v=21339&q=401405&r=1799318"
      }
    }
  },
  {
    "clubName": "Werder-Bremen",
    "id": "Werder-Bremen-gamesOverview-6",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3815886&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815886&v=21339&q=401405&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3815886&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815886&v=21339&q=401405&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3815886&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815886&v=21339&q=401405&r=1799318"
      }
    }
  },
  {
    "clubName": "Werder-Bremen",
    "id": "Werder-Bremen-gamesOverview-16",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2762553&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2762553&v=21339&q=401405&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2762553&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2762553&v=21339&q=401405&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2762553&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2762553&v=21339&q=401405&r=1799318"
      }
    }
  },
  {
    "clubName": "Werder-Bremen",
    "id": "Werder-Bremen-gamesOverview-18",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3228469&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3228469&v=21339&q=401405&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3228469&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3228469&v=21339&q=401405&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3228469&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3228469&v=21339&q=401405&r=1799318"
      }
    }
  },
  {
    "clubName": "Werder-Bremen",
    "id": "Werder-Bremen-gamesOverview-19",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2762551&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2762551&v=21339&q=401405&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2762551&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2762551&v=21339&q=401405&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2762551&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2762551&v=21339&q=401405&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        ,

          ["ranking" as AdvertisementPlace, [
  {
    "clubName": "Werder-Bremen",
    "id": "Werder-Bremen-ranking-27",
    "placement": "ranking",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3815926&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815926&v=21339&q=401405&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3815926&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815926&v=21339&q=401405&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3815926&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815926&v=21339&q=401405&r=1799318"
      }
    }
  },
  {
    "clubName": "Werder-Bremen",
    "id": "Werder-Bremen-ranking-34",
    "placement": "ranking",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2762563&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2762563&v=21339&q=401405&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2762563&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2762563&v=21339&q=401405&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2762563&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2762563&v=21339&q=401405&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        
      ])]
    ,

      ["TSG Hoffenheim", new Map([
        
          ["gamesOverview" as AdvertisementPlace, [
  {
    "clubName": "TSG-Hoffenheim",
    "id": "TSG-Hoffenheim-gamesOverview-1",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3208081&v=18564&q=444142&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3208081&v=18564&q=444142&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3208081&v=18564&q=444142&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3208081&v=18564&q=444142&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3208081&v=18564&q=444142&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3208081&v=18564&q=444142&r=1799318"
      }
    }
  },
  {
    "clubName": "TSG-Hoffenheim",
    "id": "TSG-Hoffenheim-gamesOverview-2",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3561839&v=18564&q=388664&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3561839&v=18564&q=388664&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3561839&v=18564&q=388664&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3561839&v=18564&q=388664&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3561839&v=18564&q=388664&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3561839&v=18564&q=388664&r=1799318"
      }
    }
  },
  {
    "clubName": "TSG-Hoffenheim",
    "id": "TSG-Hoffenheim-gamesOverview-3",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2632530&v=18564&q=388664&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2632530&v=18564&q=388664&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2632530&v=18564&q=388664&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2632530&v=18564&q=388664&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2632530&v=18564&q=388664&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2632530&v=18564&q=388664&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        ,

          ["inGame" as AdvertisementPlace, [
  {
    "clubName": "TSG-Hoffenheim",
    "id": "TSG-Hoffenheim-inGame-48",
    "placement": "inGame",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3561844&v=18564&q=388664&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3561844&v=18564&q=388664&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3561844&v=18564&q=388664&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3561844&v=18564&q=388664&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3561844&v=18564&q=388664&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3561844&v=18564&q=388664&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        
      ])]
    ,

      ["VfB Stuttgart", new Map([
        
          ["gamesOverview" as AdvertisementPlace, [
  {
    "clubName": "VfB-Stuttgart",
    "id": "VfB-Stuttgart-gamesOverview-4",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3688988&v=18419&q=378771&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3688988&v=18419&q=378771&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3688988&v=18419&q=378771&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3688988&v=18419&q=378771&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3688988&v=18419&q=378771&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3688988&v=18419&q=378771&r=1799318"
      }
    }
  },
  {
    "clubName": "VfB-Stuttgart",
    "id": "VfB-Stuttgart-gamesOverview-5",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3688091&v=18419&q=378771&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3688091&v=18419&q=378771&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3688091&v=18419&q=378771&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3688091&v=18419&q=378771&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3688091&v=18419&q=378771&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3688091&v=18419&q=378771&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        ,

          ["ranking" as AdvertisementPlace, [
  {
    "clubName": "VfB-Stuttgart",
    "id": "VfB-Stuttgart-ranking-25",
    "placement": "ranking",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3688945&v=18419&q=378771&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3688945&v=18419&q=378771&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3688945&v=18419&q=378771&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3688945&v=18419&q=378771&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3688945&v=18419&q=378771&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3688945&v=18419&q=378771&r=1799318"
      }
    }
  },
  {
    "clubName": "VfB-Stuttgart",
    "id": "VfB-Stuttgart-ranking-26",
    "placement": "ranking",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2537454&v=18419&q=378771&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2537454&v=18419&q=378771&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2537454&v=18419&q=378771&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2537454&v=18419&q=378771&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2537454&v=18419&q=378771&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2537454&v=18419&q=378771&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        ,

          ["inGame" as AdvertisementPlace, [
  {
    "clubName": "VfB-Stuttgart",
    "id": "VfB-Stuttgart-inGame-46",
    "placement": "inGame",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2537447&v=18419&q=378771&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2537447&v=18419&q=378771&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2537447&v=18419&q=378771&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2537447&v=18419&q=378771&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2537447&v=18419&q=378771&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2537447&v=18419&q=378771&r=1799318"
      }
    }
  },
  {
    "clubName": "VfB-Stuttgart",
    "id": "VfB-Stuttgart-inGame-47",
    "placement": "inGame",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3688090&v=18419&q=378771&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3688090&v=18419&q=378771&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3688090&v=18419&q=378771&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3688090&v=18419&q=378771&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3688090&v=18419&q=378771&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3688090&v=18419&q=378771&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        
      ])]
    ,

      ["Jahn Regensburg", new Map([
        
          ["gamesOverview" as AdvertisementPlace, [
  {
    "clubName": "Jahn-Regensburg",
    "id": "Jahn-Regensburg-gamesOverview-7",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2833000&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2833000&v=21970&q=407476&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2833000&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2833000&v=21970&q=407476&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2833000&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2833000&v=21970&q=407476&r=1799318"
      }
    }
  },
  {
    "clubName": "Jahn-Regensburg",
    "id": "Jahn-Regensburg-gamesOverview-9",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2934058&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2934058&v=21970&q=407476&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2934058&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2934058&v=21970&q=407476&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2934058&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2934058&v=21970&q=407476&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        ,

          ["ranking" as AdvertisementPlace, [
  {
    "clubName": "Jahn-Regensburg",
    "id": "Jahn-Regensburg-ranking-28",
    "placement": "ranking",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2836702&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2836702&v=21970&q=407476&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2836702&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2836702&v=21970&q=407476&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2836702&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2836702&v=21970&q=407476&r=1799318"
      }
    }
  },
  {
    "clubName": "Jahn-Regensburg",
    "id": "Jahn-Regensburg-ranking-30",
    "placement": "ranking",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3144187&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3144187&v=21970&q=407476&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3144187&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3144187&v=21970&q=407476&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3144187&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3144187&v=21970&q=407476&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        ,

          ["inGame" as AdvertisementPlace, [
  {
    "clubName": "Jahn-Regensburg",
    "id": "Jahn-Regensburg-inGame-37",
    "placement": "inGame",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2932382&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2932382&v=21970&q=407476&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2932382&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2932382&v=21970&q=407476&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2932382&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2932382&v=21970&q=407476&r=1799318"
      }
    }
  },
  {
    "clubName": "Jahn-Regensburg",
    "id": "Jahn-Regensburg-inGame-38",
    "placement": "inGame",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3074870&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3074870&v=21970&q=407476&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3074870&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3074870&v=21970&q=407476&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3074870&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3074870&v=21970&q=407476&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        
      ])]
    ,

      ["Darmstadt 98", new Map([
        
          ["gamesOverview" as AdvertisementPlace, [
  {
    "clubName": "Darmstadt-98",
    "id": "Darmstadt-98-gamesOverview-8",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3199934&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3199934&v=20984&q=400236&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3199934&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3199934&v=20984&q=400236&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3199934&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3199934&v=20984&q=400236&r=1799318"
      }
    }
  },
  {
    "clubName": "Darmstadt-98",
    "id": "Darmstadt-98-gamesOverview-12",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2808882&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2808882&v=20984&q=400236&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2808882&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2808882&v=20984&q=400236&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2808882&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2808882&v=20984&q=400236&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        ,

          ["ranking" as AdvertisementPlace, [
  {
    "clubName": "Darmstadt-98",
    "id": "Darmstadt-98-ranking-29",
    "placement": "ranking",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2783264&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2783264&v=20984&q=400236&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2783264&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2783264&v=20984&q=400236&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2783264&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2783264&v=20984&q=400236&r=1799318"
      }
    }
  },
  {
    "clubName": "Darmstadt-98",
    "id": "Darmstadt-98-ranking-33",
    "placement": "ranking",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3127051&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3127051&v=20984&q=400236&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3127051&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3127051&v=20984&q=400236&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3127051&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3127051&v=20984&q=400236&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        
      ])]
    ,

      ["Union Berlin", new Map([
        
          ["gamesOverview" as AdvertisementPlace, [
  {
    "clubName": "Union-Berlin",
    "id": "Union-Berlin-gamesOverview-10",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3349420&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3349420&v=41734&q=457527&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3349420&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3349420&v=41734&q=457527&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3349420&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3349420&v=41734&q=457527&r=1799318"
      }
    }
  },
  {
    "clubName": "Union-Berlin",
    "id": "Union-Berlin-gamesOverview-11",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3325963&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3325963&v=41734&q=457527&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3325963&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3325963&v=41734&q=457527&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3325963&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3325963&v=41734&q=457527&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        ,

          ["ranking" as AdvertisementPlace, [
  {
    "clubName": "Union-Berlin",
    "id": "Union-Berlin-ranking-31",
    "placement": "ranking",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3349434&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3349434&v=41734&q=457527&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3349434&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3349434&v=41734&q=457527&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3349434&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3349434&v=41734&q=457527&r=1799318"
      }
    }
  },
  {
    "clubName": "Union-Berlin",
    "id": "Union-Berlin-ranking-32",
    "placement": "ranking",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3325989&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3325989&v=41734&q=457527&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3325989&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3325989&v=41734&q=457527&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3325989&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3325989&v=41734&q=457527&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        ,

          ["inGame" as AdvertisementPlace, [
  {
    "clubName": "Union-Berlin",
    "id": "Union-Berlin-inGame-39",
    "placement": "inGame",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3349421&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3349421&v=41734&q=457527&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3349421&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3349421&v=41734&q=457527&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3349421&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3349421&v=41734&q=457527&r=1799318"
      }
    }
  },
  {
    "clubName": "Union-Berlin",
    "id": "Union-Berlin-inGame-40",
    "placement": "inGame",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3325964&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3325964&v=41734&q=457527&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3325964&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3325964&v=41734&q=457527&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3325964&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3325964&v=41734&q=457527&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        
      ])]
    ,

      ["Schalke 04", new Map([
        
          ["gamesOverview" as AdvertisementPlace, [
  {
    "clubName": "Schalke-04",
    "id": "Schalke-04-gamesOverview-13",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2420881&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420881&v=13879&q=366594&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2420881&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420881&v=13879&q=366594&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2420881&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420881&v=13879&q=366594&r=1799318"
      }
    }
  },
  {
    "clubName": "Schalke-04",
    "id": "Schalke-04-gamesOverview-15",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2420875&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420875&v=13879&q=366594&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2420875&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420875&v=13879&q=366594&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2420875&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420875&v=13879&q=366594&r=1799318"
      }
    }
  },
  {
    "clubName": "Schalke-04",
    "id": "Schalke-04-gamesOverview-17",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2420861&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420861&v=13879&q=366594&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2420861&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420861&v=13879&q=366594&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2420861&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420861&v=13879&q=366594&r=1799318"
      }
    }
  },
  {
    "clubName": "Schalke-04",
    "id": "Schalke-04-gamesOverview-20",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2420892&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420892&v=13879&q=366594&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2420892&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420892&v=13879&q=366594&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2420892&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420892&v=13879&q=366594&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        ,

          ["inGame" as AdvertisementPlace, [
  {
    "clubName": "Schalke-04",
    "id": "Schalke-04-inGame-43",
    "placement": "inGame",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2420874&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420874&v=13879&q=366594&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2420874&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420874&v=13879&q=366594&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2420874&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420874&v=13879&q=366594&r=1799318"
      }
    }
  },
  {
    "clubName": "Schalke-04",
    "id": "Schalke-04-inGame-44",
    "placement": "inGame",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2765458&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2765458&v=13879&q=366594&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2765458&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2765458&v=13879&q=366594&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2765458&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2765458&v=13879&q=366594&r=1799318"
      }
    }
  },
  {
    "clubName": "Schalke-04",
    "id": "Schalke-04-inGame-45",
    "placement": "inGame",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2420860&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420860&v=13879&q=366594&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2420860&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420860&v=13879&q=366594&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2420860&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420860&v=13879&q=366594&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        
      ])]
    ,

      ["VfL Bochum", new Map([
        
          ["gamesOverview" as AdvertisementPlace, [
  {
    "clubName": "VfL-Bochum",
    "id": "VfL-Bochum-gamesOverview-14",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2806145&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2806145&v=19956&q=393457&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2806145&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2806145&v=19956&q=393457&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2806145&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2806145&v=19956&q=393457&r=1799318"
      }
    }
  },
  {
    "clubName": "VfL-Bochum",
    "id": "VfL-Bochum-gamesOverview-21",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2714530&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2714530&v=19956&q=393457&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2714530&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2714530&v=19956&q=393457&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2714530&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2714530&v=19956&q=393457&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        ,

          ["ranking" as AdvertisementPlace, [
  {
    "clubName": "VfL-Bochum",
    "id": "VfL-Bochum-ranking-35",
    "placement": "ranking",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2714531&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2714531&v=19956&q=393457&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2714531&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2714531&v=19956&q=393457&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2714531&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2714531&v=19956&q=393457&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        ,

          ["inGame" as AdvertisementPlace, [
  {
    "clubName": "VfL-Bochum",
    "id": "VfL-Bochum-inGame-41",
    "placement": "inGame",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2691727&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2691727&v=19956&q=393457&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2691727&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2691727&v=19956&q=393457&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2691727&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2691727&v=19956&q=393457&r=1799318"
      }
    }
  },
  {
    "clubName": "VfL-Bochum",
    "id": "VfL-Bochum-inGame-42",
    "placement": "inGame",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3208599&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3208599&v=19956&q=393457&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3208599&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3208599&v=19956&q=393457&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3208599&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3208599&v=19956&q=393457&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        
      ])]
    ,

      ["Bayer Leverkusen", new Map([
        
          ["gamesOverview" as AdvertisementPlace, [
  {
    "clubName": "Bayer-Leverkusen",
    "id": "Bayer-Leverkusen-gamesOverview-22",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3479016&v=66252&q=474102&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3479016&v=66252&q=474102&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3479016&v=66252&q=474102&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3479016&v=66252&q=474102&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3479016&v=66252&q=474102&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3479016&v=66252&q=474102&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        ,

          ["ranking" as AdvertisementPlace, [
  {
    "clubName": "Bayer-Leverkusen",
    "id": "Bayer-Leverkusen-ranking-36",
    "placement": "ranking",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3574079&v=66252&q=483928&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3574079&v=66252&q=483928&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3574079&v=66252&q=483928&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3574079&v=66252&q=483928&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3574079&v=66252&q=483928&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3574079&v=66252&q=483928&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        
      ])]
    ,

      ["FC Bayern München", new Map([
        
          ["gamesOverview" as AdvertisementPlace, [
  {
    "clubName": "FC-Bayern-München",
    "id": "FC-Bayern-München-gamesOverview-23",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2508146&v=14076&q=375797&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2508146&v=14076&q=375797&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2508146&v=14076&q=375797&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2508146&v=14076&q=375797&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2508146&v=14076&q=375797&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2508146&v=14076&q=375797&r=1799318"
      }
    }
  },
  {
    "clubName": "FC-Bayern-München",
    "id": "FC-Bayern-München-gamesOverview-24",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2508164&v=14076&q=375242&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2508164&v=14076&q=375242&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2508164&v=14076&q=375242&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2508164&v=14076&q=375242&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2508164&v=14076&q=375242&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2508164&v=14076&q=375242&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        
      ])]
    
]);
