import { catchError, of, switchMap, take, tap, timeout } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { UserFacade } from '@kiq/client/data-access/user';
import { Auth } from '@angular/fire/auth';
import { AppAuthenticationService, AuthenticationService } from '@kiq/shared/data-access/authentication';
import { RemoteConfigFacade } from '@kiq/client/data-access/remote-config';
import { AdvertisementFacade } from '@kiq/client/data-access/advertisement';

export function initializeApplication(
  actions: Actions,
  facade: UserFacade,
  auth: Auth,
  authenticatioService: AuthenticationService | AppAuthenticationService,
  remoteConfigFacade: RemoteConfigFacade,
  advertisementFacade: AdvertisementFacade,
) {
  return () => {
    return authenticatioService.token$.pipe(
      take(1),
      tap((token) => {
        remoteConfigFacade.fetchRemoteConfig();
        advertisementFacade.initAllAdvertisementAssetsTypes();

        if (!token) {
          return;
        }

        facade.getBackendUser();
      }),
      switchMap((token) => {
        if (!token) {
          return of(true);
        }

        return actions.pipe(ofType(facade.getBackendUserSuccess, facade.getBackendUserFail), take(1));
      }),
      timeout(2000),
      catchError((error) => {
        console.error('Error while initializing the app', error);
        return of(true);
      }),
    );
  };
}
