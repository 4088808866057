import { createFeature, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { RemoteConfigActions } from './remote-config.actions';
import { RemoteConfigKeys } from '../enum/remote-config-keys';

export interface RemoteConfigState {
  initialAppVersion: string | null;
  maintenanceStart: string | null;
  maintenanceEnd: string | null;
  maintenanceNotice: boolean;
  maintenanceMode: boolean;
}

export const initialRemoteConfigState: RemoteConfigState = {
  initialAppVersion: null,
  maintenanceNotice: false,
  maintenanceStart: null,
  maintenanceEnd: null,
  maintenanceMode: false,
};

export const remoteConfigFeature = createFeature({
  name: 'remoteConfig',
  reducer: createReducer(
    initialRemoteConfigState,
    immerOn(RemoteConfigActions.fetchRemoteConfigSuccess, (state, { currentRemoteConfig }) => {
      state.initialAppVersion = currentRemoteConfig?.[RemoteConfigKeys.appVersion]?.asString() ?? null;
      state.maintenanceNotice = currentRemoteConfig?.[RemoteConfigKeys.maintenanceNotice]?.asBoolean() ?? false;
      state.maintenanceStart = currentRemoteConfig?.[RemoteConfigKeys.maintenanceStart]?.asString() ?? null;
      state.maintenanceEnd = currentRemoteConfig?.[RemoteConfigKeys.maintenanceEnd]?.asString() ?? null;
      state.maintenanceMode = currentRemoteConfig?.[RemoteConfigKeys.maintenanceMode]?.asBoolean() ?? false;
    }),
  ),
});

export const {
  name,
  reducer,
  selectInitialAppVersion,
  selectMaintenanceNotice,
  selectMaintenanceStart,
  selectMaintenanceEnd,
  selectMaintenanceMode,
} = remoteConfigFeature;
