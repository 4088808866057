import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { trophyFeature } from './+state/trophy.reducer';
import { TrophyEffects } from './+state/trophy.effects';
import { TrophyFacade } from './+state/trophy.facade';
import { TrophyService } from './service/trophy.service';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { scopeLoader } from '@kiq/shared/util/translation';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(trophyFeature),
    EffectsModule.forFeature([TrophyEffects]),
  ],
  providers: [
    TrophyFacade,
    TrophyService,
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'trophiesDataAccess',
        loader: scopeLoader((lang: string, root: string) => import(`../${root}/${lang}.json`)),
      },
    },
  ],
})
export class SharedDataAccessTrophyModule {}
