import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Value } from '@angular/fire/remote-config';

export const RemoteConfigActions = createActionGroup({
  source: 'Remote Config',
  events: {
    'Fetch Remote config start': emptyProps(),
    'Fetch Remote config  success': props<{ currentRemoteConfig: Record<string, Value> }>(),
    'Fetch Remote config  error': emptyProps(),
  },
});
