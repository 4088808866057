import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectInitialAppVersion,
  selectMaintenanceEnd,
  selectMaintenanceMode,
  selectMaintenanceNotice,
  selectMaintenanceStart,
} from './remote-config.reducer';
import { RemoteConfigActions } from './remote-config.actions';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RemoteConfigFacade {
  private readonly store = inject(Store);

  readonly initialAppVersion$: Observable<string | null> = this.store.select(selectInitialAppVersion);
  readonly maintenanceNotice$: Observable<boolean> = this.store.select(selectMaintenanceNotice);
  readonly maintenanceStart$: Observable<string | null> = this.store.select(selectMaintenanceStart);
  readonly maintenanceEnd$: Observable<string | null> = this.store.select(selectMaintenanceEnd);
  readonly maintenanceMode$: Observable<boolean> = this.store.select(selectMaintenanceMode);

  fetchRemoteConfig() {
    this.store.dispatch(RemoteConfigActions.fetchRemoteConfigStart());
  }
}
