import { Media } from './media';
import { VipUserQuizduelGameData } from '../../../../types/src/lib/types/vip-user-quizduel-game-data';

export type VipUserQuizduelGameBackofficeView = {
  id?: string;
  gameLinkId?: string;
  gameData: VipUserQuizduelGameData;
  communityPreviewStart?: Date;
  communityPreviewWithAllDetails: boolean;
  communityGameStart?: Date;
  communityGameEnd?: Date;
  reactionOnWinMedia?: Media;
  reactionOnLossMedia?: Media;
  reactionOnDrawMedia?: Media;
  deletionAllowed: boolean;
};
