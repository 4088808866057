import { Authentication } from './authentication.interface';
import { catchError, filter, from, map, of, skip, switchMap, take, tap, timeout } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { FirebaseService, UserFacade } from '@kiq/client/data-access/user';
import { NativeAppsService } from '@kiq/shared/data-access/native-apps';
import { Actions, concatLatestFrom, ofType } from '@ngrx/effects';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable()
export class AppAuthenticationService implements Authentication {
  private readonly nativeAppService = inject(NativeAppsService);
  private readonly userFacade = inject(UserFacade);
  private readonly actions = inject(Actions);
  private readonly firebaseService = inject(FirebaseService);
  private readonly nativeLoginInProgress = toSignal(this.userFacade.nativeLoginInProgress$);

  readonly authChanges$ = this.firebaseService.authChanges$;
  readonly token$ = this.firebaseService.firebaseUser$.pipe(
    tap((user) => console.log('user in firebase$ stream', user)),
    switchMap((user) => (user ? from(user.getIdToken()) : of(null))),
  );
  readonly authChanges = toSignal(
    this.authChanges$.pipe(
      timeout(2000),
      catchError(() => of(null)),
    ),
    { initialValue: null },
  );

  constructor() {
    this.actions.pipe(ofType(this.userFacade.getBackendUserFail)).subscribe(() => this.logout());

    // Force signal to subscribe to authChanges$ stream. Needed because signals are lazy by default.
    // Otherwise, on first call the signal would return only the initial value.
    this.authChanges();
  }

  login(): void {
    this.nativeAppService.openLoginModal();
  }

  logout(): void {
    // Prevent loop with native app because of app_initialized login event to native app triggers app to login
    this.userFacade.logout();
    this.authChanges$.pipe(skip(1), take(1)).subscribe((user) => this.nativeAppService.logout());
  }

  register(): void {
    this.nativeAppService.openRegisterModal();
  }

  signInWithCustomToken(token: string) {
    const isLoggedIn = this.authChanges() != null;
    const nativeLoginInProgress = this.nativeLoginInProgress();

    if (isLoggedIn || nativeLoginInProgress) {
      if (isLoggedIn) {
        console.warn('Login attempt skipped: user already logged in.');
      } else {
        console.warn('Login attempt skipped: login already in progress.');
      }

      return;
    }

    this.userFacade.setNativeLoginInProgress(true);

    from(this.firebaseService.signInWithCustomToken(token)).subscribe({
      next: (userCredentials) => {
        this.userFacade.getOrCreateBackendUserAfterLoginWithCustomAuthToken(userCredentials);
      },
      error: (error) => {
        console.error('Login mit bestehendem Custom Token fehlgeschlagen:', error);
        this.userFacade.logout();
        this.nativeAppService.logout();
        this.userFacade.setNativeLoginInProgress(false);
      },
    });
  }

  deleteAccount(): void {
    this.userFacade.deleteAccount();
    this.userFacade.user$.pipe(skip(1), take(1)).subscribe((user) => {
      if (!user) {
        this.logout();
      }
    });
  }
}
