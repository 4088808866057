import { AsyncTicTacToeQuestion } from './async-tic-tac-toe-question';
import { GameTopscorerQuestion } from './game-topscorer-question';
import { GameTransferHistoryQuestion } from './game-transfer-history-question';

export type GameQuizduelQuestionInternal = {
  maxAnswerDurationSeconds: number;
  ticTacToe: AsyncTicTacToeQuestion;
  topscorer: GameTopscorerQuestion;
  transferHistory: GameTransferHistoryQuestion;
};
