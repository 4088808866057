import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs';
import { AssetTransformer } from '@kiq/shared/interfaces';

@Injectable()
export class AssetsHelperService implements AssetTransformer {
  private readonly ASSET_MAP_URL = '/assets/assets.json';
  private readonly http = inject(HttpClient);

  private hashedAssetsMap: Map<string, string> = new Map<string, string>();

  initHashedAssetsMap$() {
    return this.http.get<{ [key: string]: string }>(this.ASSET_MAP_URL).pipe(
      tap((assetsMapJson: { [key: string]: string }) => {
        const assetsMap = new Map<string, string>();

        Object.keys(assetsMapJson).forEach((key: string) => {
          assetsMap.set(key, assetsMapJson[key]);
        });

        this.hashedAssetsMap = assetsMap;
      }),
    );
  }

  getAssetWithHash(assetPathWithoutHash: string): string {
    return this.hashedAssetsMap.get(assetPathWithoutHash) ?? assetPathWithoutHash;
  }
}
