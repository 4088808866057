import { DeviceType } from '@kiq/shared/enums';

export type DeviceToken = {
  deviceType: DeviceType;
  appInstallId: string;
  fcmToken?: string;
  apnsDeviceToken?: string;
  userFirebaseId: string;
  language: string;
};
