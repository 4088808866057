import { TranslocoService } from '@jsverse/transloco';
import { AVAILABLE_LANGS } from '../transloco-loader/scope-loader';
import { AvailableLanguages } from '../enum/available-languages';

export class LanguageInitializerService {
  constructor(
    private translocoService: TranslocoService,
    private languageFeatureFlag: boolean,
  ) {}

  initializeLanguage(): Promise<void> {
    return new Promise((resolve) => {
      // Detect browser language
      const browserLang = navigator.language.split('-')[0]; // e.g., 'en-US' -> 'en'
      const availableLangs = AVAILABLE_LANGS;
      let defaultLang: AvailableLanguages | string = AvailableLanguages.DE;

      if (this.languageFeatureFlag) {
        defaultLang = availableLangs.find((language) => language.toString() === browserLang)
          ? browserLang
          : AvailableLanguages.DE;
      }

      // Set the default language dynamically
      this.translocoService.setDefaultLang(defaultLang);
      this.translocoService.setActiveLang(defaultLang);

      resolve();
    });
  }
}
